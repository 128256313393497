import React, { useRef,useMemo } from "react";
import * as THREE from 'three';
import { useGLTF, useAnimations } from "@react-three/drei";
import { useConvexPolyhedron } from "@react-three/cannon";


const modelUrl = process.env.REACT_APP_MODELPATH+"basemodel.glb";
// const modelUrl = "/assets/basemodel.glb";

export default function Model(props) {

  // const { nodes, materials, animations } = useGLTF("/assets/basemodel.glb");
  const { nodes, materials, animations } = useGLTF(modelUrl);
  const group = useRef();
  const { actions } = useAnimations(animations, group);
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="Object171" />
        <group name="Object474" />
        <group name="Mesh" />
        <group name="Mesh001" />
        <group name="Object249" />
        <group name="Object251" />
        <group name="Object252" />
        <group name="Object178" />
        <group name="Object181" />
        <group name="Object182" />
        <group name="Object257" />
        <group name="Object386" />
        <group name="Object397" />
        <group name="Object398" />
        <group name="Object074" />
        <group name="Object367" />
        <group name="Object401" />
        <group name="Object416" />
        <group name="Object496" />
        <group name="Object539" />
        <group name="Object541" />
        <group name="Object543" />
        <group name="Object076" />
        <group name="Object180" />
        <group name="Object185" />
        <group name="Object187" />
        <group name="Object188" />
        <group name="Object189" />
        <group name="Object190" />
        <group name="Object191" />
        <group name="Object192" />
        <group name="Object193" />
        <group name="Object194" />
        <group name="Object195" />
        <group name="Object202" />
        <group name="Object244" />
        <group name="Object245" />
        <group name="Object246" />
        <group name="Object247" />
        <group name="Object526" />
        <group name="Object528" />
        <group name="Object165" />
        <group name="Object170" />
        <group name="Object172" />
        <group name="Object173" />
        <group name="Object174" />
        <group name="Object175" />
        <group name="Object176" />
        <group name="Object177" />
        <group name="Object183" />
        <group name="Object184" />
        <group name="Object186" />
        <group name="Object196" />
        <group name="Object197" />
        <group name="Object198" />
        <group name="Object199" />
        <group name="Object200" />
        <group name="Object201" />
        <group name="Object236" />
        <group name="Object238" />
        <group name="Object240" />
        <group name="Object241" />
        <group name="Object243" />
        <group name="Object394" />
        <group name="Object396" />
        <group name="Object412" />
        <group name="Object413" />
        <group name="Object579" position={[-27.26, 0.35, -4.57]} />
        <group name="Object580" position={[-27.26, 0.35, -4.57]} />
        <group name="Object583" position={[-28.6, 0.35, -4.52]} />
        <group name="Object584" position={[-28.6, 0.35, -4.52]} />
        <group name="Object587" position={[-29.94, 0.35, -4.52]} />
        <group name="Object588" position={[-29.94, 0.35, -4.52]} />
        <group name="Object591" position={[-31.28, 0.35, -4.57]} />
        <group name="Object592" position={[-31.28, 0.35, -4.57]} />
        <group name="Object595" position={[-32.62, 0.35, -4.57]} />
        <group name="Object596" position={[-32.62, 0.35, -4.57]} />
        <group name="Object126" />
        <group name="Object127" />
        <group name="Object130" />
        <group name="Object323" />
        <group name="Object324" />
        <group name="Object325" />
        <group name="Object326" />
        <group name="Object327" />
        <group name="Object328" />
        <mesh
          name="Object066"
          castShadow
          receiveShadow
          geometry={nodes.Object066.geometry}
          material={nodes.Object066.material}
        />
        <mesh
          name="Object168"
          castShadow
          receiveShadow
          geometry={nodes.Object168.geometry}
          material={nodes.Object168.material}
        />
        <mesh
          name="Object169"
          castShadow
          receiveShadow
          geometry={nodes.Object169.geometry}
          material={nodes.Object169.material}
        />
        <mesh
          name="Object167"
          castShadow
          receiveShadow
          geometry={nodes.Object167.geometry}
          material={nodes.Object167.material}
        />
        <mesh
          name="Object266"
          castShadow
          receiveShadow
          geometry={nodes.Object266.geometry}
          material={nodes.Object266.material}
        />
        <mesh
          name="Object433"
          castShadow
          receiveShadow
          geometry={nodes.Object433.geometry}
          material={nodes.Object433.material}
        />
        <mesh
          name="Object473"
          castShadow
          receiveShadow
          geometry={nodes.Object473.geometry}
          material={nodes.Object473.material}
        />
        <mesh
          name="Object133"
          castShadow
          receiveShadow
          geometry={nodes.Object133.geometry}
          material={nodes.Object133.material}
        />
        <mesh
          name="Object134"
          castShadow
          receiveShadow
          geometry={nodes.Object134.geometry}
          material={nodes.Object134.material}
        />
        <mesh
          name="Object135"
          castShadow
          receiveShadow
          geometry={nodes.Object135.geometry}
          material={nodes.Object135.material}
        />
        <mesh
          name="Object136"
          castShadow
          receiveShadow
          geometry={nodes.Object136.geometry}
          material={nodes.Object136.material}
        />
        <mesh
          name="Object137"
          castShadow
          receiveShadow
          geometry={nodes.Object137.geometry}
          material={nodes.Object137.material}
        />
        <mesh
          name="Object138"
          castShadow
          receiveShadow
          geometry={nodes.Object138.geometry}
          material={nodes.Object138.material}
        />
        <mesh
          name="Object139"
          castShadow
          receiveShadow
          geometry={nodes.Object139.geometry}
          material={nodes.Object139.material}
        />
        <mesh
          name="Object140"
          castShadow
          receiveShadow
          geometry={nodes.Object140.geometry}
          material={nodes.Object140.material}
        />
        <mesh
          name="Object141"
          castShadow
          receiveShadow
          geometry={nodes.Object141.geometry}
          material={nodes.Object141.material}
        />
        <mesh
          name="Object142"
          castShadow
          receiveShadow
          geometry={nodes.Object142.geometry}
          material={nodes.Object142.material}
        />
        <mesh
          name="Object143"
          castShadow
          receiveShadow
          geometry={nodes.Object143.geometry}
          material={nodes.Object143.material}
        />
        <mesh
          name="Object144"
          castShadow
          receiveShadow
          geometry={nodes.Object144.geometry}
          material={nodes.Object144.material}
        />
        <mesh
          name="Object145"
          castShadow
          receiveShadow
          geometry={nodes.Object145.geometry}
          material={nodes.Object145.material}
        />
        <mesh
          name="Object146"
          castShadow
          receiveShadow
          geometry={nodes.Object146.geometry}
          material={nodes.Object146.material}
        />
        <mesh
          name="Object147"
          castShadow
          receiveShadow
          geometry={nodes.Object147.geometry}
          material={nodes.Object147.material}
        />
        <mesh
          name="Object148"
          castShadow
          receiveShadow
          geometry={nodes.Object148.geometry}
          material={nodes.Object148.material}
        />
        <mesh
          name="Object149"
          castShadow
          receiveShadow
          geometry={nodes.Object149.geometry}
          material={nodes.Object149.material}
        />
        <mesh
          name="Object150"
          castShadow
          receiveShadow
          geometry={nodes.Object150.geometry}
          material={nodes.Object150.material}
        />
        <mesh
          name="Object151"
          castShadow
          receiveShadow
          geometry={nodes.Object151.geometry}
          material={nodes.Object151.material}
        />
        <mesh
          name="Object153"
          castShadow
          receiveShadow
          geometry={nodes.Object153.geometry}
          material={nodes.Object153.material}
        />
        <mesh
          name="Object155"
          castShadow
          receiveShadow
          geometry={nodes.Object155.geometry}
          material={nodes.Object155.material}
        />
        <mesh
          name="Object156"
          castShadow
          receiveShadow
          geometry={nodes.Object156.geometry}
          material={nodes.Object156.material}
        />
        <mesh
          name="Object157"
          castShadow
          receiveShadow
          geometry={nodes.Object157.geometry}
          material={nodes.Object157.material}
        />
        <mesh
          name="Object158"
          castShadow
          receiveShadow
          geometry={nodes.Object158.geometry}
          material={nodes.Object158.material}
        />
        <mesh
          name="Object159"
          castShadow
          receiveShadow
          geometry={nodes.Object159.geometry}
          material={nodes.Object159.material}
        />
        <mesh
          name="Object160"
          castShadow
          receiveShadow
          geometry={nodes.Object160.geometry}
          material={nodes.Object160.material}
        />
        <mesh
          name="Object161"
          castShadow
          receiveShadow
          geometry={nodes.Object161.geometry}
          material={nodes.Object161.material}
        />
        <mesh
          name="Object162"
          castShadow
          receiveShadow
          geometry={nodes.Object162.geometry}
          material={nodes.Object162.material}
        />
        <mesh
          name="Object163"
          castShadow
          receiveShadow
          geometry={nodes.Object163.geometry}
          material={nodes.Object163.material}
        />
        <mesh
          name="Object164"
          castShadow
          receiveShadow
          geometry={nodes.Object164.geometry}
          material={nodes.Object164.material}
        />
        <mesh
          name="Object001"
          castShadow
          receiveShadow
          geometry={nodes.Object001.geometry}
          material={materials["grass.001"]}
        />
        <mesh
          name="Object002"
          castShadow
          receiveShadow
          geometry={nodes.Object002.geometry}
          material={materials["grass.002"]}
        />
        <mesh
          name="Object071"
          castShadow
          receiveShadow
          geometry={nodes.Object071.geometry}
          material={nodes.Object071.material}
        />
        <mesh
          name="Object179"
          castShadow
          receiveShadow
          geometry={nodes.Object179.geometry}
          material={nodes.Object179.material}
        />
        <mesh
          name="Object205"
          castShadow
          receiveShadow
          geometry={nodes.Object205.geometry}
          material={nodes.Object205.material}
        />
        <mesh
          name="Object206"
          castShadow
          receiveShadow
          geometry={nodes.Object206.geometry}
          material={nodes.Object206.material}
        />
        <mesh
          name="Object208"
          castShadow
          receiveShadow
          geometry={nodes.Object208.geometry}
          material={nodes.Object208.material}
        />
        <mesh
          name="Object424"
          castShadow
          receiveShadow
          geometry={nodes.Object424.geometry}
          material={nodes.Object424.material}
        />
        <mesh
          name="Object425"
          castShadow
          receiveShadow
          geometry={nodes.Object425.geometry}
          material={nodes.Object425.material}
        />
        <mesh
          name="Object426"
          castShadow
          receiveShadow
          geometry={nodes.Object426.geometry}
          material={nodes.Object426.material}
        />
        <mesh
          name="Object521"
          castShadow
          receiveShadow
          geometry={nodes.Object521.geometry}
          material={nodes.Object521.material}
        />
        <mesh
          name="Object075"
          castShadow
          receiveShadow
          geometry={nodes.Object075.geometry}
          material={nodes.Object075.material}
        />
        <mesh
          name="Object131"
          castShadow
          receiveShadow
          geometry={nodes.Object131.geometry}
          material={nodes.Object131.material}
        />
        <mesh
          name="Object166"
          castShadow
          receiveShadow
          geometry={nodes.Object166.geometry}
          material={nodes.Object166.material}
        />
        <mesh
          name="Object237"
          castShadow
          receiveShadow
          geometry={nodes.Object237.geometry}
          material={nodes.Object237.material}
        />
        <mesh
          name="Object347"
          castShadow
          receiveShadow
          geometry={nodes.Object347.geometry}
          material={nodes.Object347.material}
        />
        <mesh
          name="Object349"
          castShadow
          receiveShadow
          geometry={nodes.Object349.geometry}
          material={nodes.Object349.material}
        />
        <mesh
          name="Object395"
          castShadow
          receiveShadow
          geometry={nodes.Object395.geometry}
          material={nodes.Object395.material}
        />
        <mesh
          name="Object407"
          castShadow
          receiveShadow
          geometry={nodes.Object407.geometry}
          material={nodes.Object407.material}
        />
        <mesh
          name="Object409"
          castShadow
          receiveShadow
          geometry={nodes.Object409.geometry}
          material={nodes.Object409.material}
        />
        <mesh
          name="Object417"
          castShadow
          receiveShadow
          geometry={nodes.Object417.geometry}
          material={nodes.Object417.material}
        />
        <mesh
          name="Object421"
          castShadow
          receiveShadow
          geometry={nodes.Object421.geometry}
          material={nodes.Object421.material}
        />
        <mesh
          name="Object422"
          castShadow
          receiveShadow
          geometry={nodes.Object422.geometry}
          material={nodes.Object422.material}
        />
        <mesh
          name="Object423"
          castShadow
          receiveShadow
          geometry={nodes.Object423.geometry}
          material={nodes.Object423.material}
        />
        <mesh
          name="Object569"
          castShadow
          receiveShadow
          geometry={nodes.Object569.geometry}
          material={nodes.Object569.material}
        />
        <mesh
          name="Object573"
          castShadow
          receiveShadow
          geometry={nodes.Object573.geometry}
          material={nodes.Object573.material}
          position={[-36.06, 5.1, -12.46]}
          rotation={[0, 1.57, 0]}
        />
        <mesh
          name="Object575"
          castShadow
          receiveShadow
          geometry={nodes.Object575.geometry}
          material={nodes.Object575.material}
          position={[-35.96, 5.1, -10.63]}
          rotation={[0, 1.57, 0]}
        />
        <mesh
          name="Object578"
          castShadow
          receiveShadow
          geometry={nodes.Object578.geometry}
          material={nodes.Object578.material}
          position={[-27.26, 0.35, -4.57]}
        />
        <mesh
          name="Object582"
          castShadow
          receiveShadow
          geometry={nodes.Object582.geometry}
          material={nodes.Object582.material}
          position={[-28.6, 0.35, -4.52]}
        />
        <mesh
          name="Object586"
          castShadow
          receiveShadow
          geometry={nodes.Object586.geometry}
          material={nodes.Object586.material}
          position={[-29.94, 0.35, -4.52]}
        />
        <mesh
          name="Object590"
          castShadow
          receiveShadow
          geometry={nodes.Object590.geometry}
          material={nodes.Object590.material}
          position={[-31.28, 0.35, -4.57]}
        />
        <mesh
          name="Object594"
          castShadow
          receiveShadow
          geometry={nodes.Object594.geometry}
          material={nodes.Object594.material}
          position={[-32.62, 0.35, -4.57]}
        />
        <mesh
          name="Object597"
          castShadow
          receiveShadow
          geometry={nodes.Object597.geometry}
          material={nodes.Object597.material}
          position={[-29.35, 5.1, -12.46]}
        />
        <mesh
          name="Object599"
          castShadow
          receiveShadow
          geometry={nodes.Object599.geometry}
          material={nodes.Object599.material}
          position={[-31.53, 5.1, -12.36]}
        />
        <mesh
          name="Object601"
          castShadow
          receiveShadow
          geometry={nodes.Object601.geometry}
          material={nodes.Object601.material}
          position={[-33.7, 5.1, -12.36]}
        />
        <mesh
          name="Object603"
          castShadow
          receiveShadow
          geometry={nodes.Object603.geometry}
          material={nodes.Object603.material}
          position={[-35.88, 5.1, -12.46]}
        />
        <mesh
          name="Object128"
          castShadow
          receiveShadow
          geometry={nodes.Object128.geometry}
          material={nodes.Object128.material}
        />
        <mesh
          name="Object329"
          castShadow
          receiveShadow
          geometry={nodes.Object329.geometry}
          material={nodes.Object329.material}
        />
        <mesh
          name="Object330"
          castShadow
          receiveShadow
          geometry={nodes.Object330.geometry}
          material={nodes.Object330.material}
        />
        <mesh
          name="Object250"
          castShadow
          receiveShadow
          geometry={nodes.Object250.geometry}
          material={nodes.Object250.material}
          morphTargetDictionary={nodes.Object250.morphTargetDictionary}
          morphTargetInfluences={nodes.Object250.morphTargetInfluences}
        />
        <mesh
          name="Object253"
          castShadow
          receiveShadow
          geometry={nodes.Object253.geometry}
          material={nodes.Object253.material}
          morphTargetDictionary={nodes.Object253.morphTargetDictionary}
          morphTargetInfluences={nodes.Object253.morphTargetInfluences}
        />
        <mesh
          name="Object255"
          castShadow
          receiveShadow
          geometry={nodes.Object255.geometry}
          material={nodes.Object255.material}
          morphTargetDictionary={nodes.Object255.morphTargetDictionary}
          morphTargetInfluences={nodes.Object255.morphTargetInfluences}
        />
        <mesh
          name="Object254"
          castShadow
          receiveShadow
          geometry={nodes.Object254.geometry}
          material={nodes.Object254.material}
          morphTargetDictionary={nodes.Object254.morphTargetDictionary}
          morphTargetInfluences={nodes.Object254.morphTargetInfluences}
        />
        <mesh
          name="Object203"
          castShadow
          receiveShadow
          geometry={nodes.Object203.geometry}
          material={nodes.Object203.material}
        />
        <mesh
          name="Object434"
          castShadow
          receiveShadow
          geometry={nodes.Object434.geometry}
          material={nodes.Object434.material}
        />
        <mesh
          name="Object073"
          castShadow
          receiveShadow
          geometry={nodes.Object073.geometry}
          material={nodes.Object073.material}
        />
        <mesh
          name="Object125"
          castShadow
          receiveShadow
          geometry={nodes.Object125.geometry}
          material={nodes.Object125.material}
        />
        <mesh
          name="Object129"
          castShadow
          receiveShadow
          geometry={nodes.Object129.geometry}
          material={nodes.Object129.material}
        />
        <mesh
          name="Object242"
          castShadow
          receiveShadow
          geometry={nodes.Object242.geometry}
          material={nodes.Object242.material}
        />
        <mesh
          name="Object258"
          castShadow
          receiveShadow
          geometry={nodes.Object258.geometry}
          material={nodes.Object258.material}
        />
        <mesh
          name="Object259"
          castShadow
          receiveShadow
          geometry={nodes.Object259.geometry}
          material={nodes.Object259.material}
        />
        <mesh
          name="Object260"
          castShadow
          receiveShadow
          geometry={nodes.Object260.geometry}
          material={nodes.Object260.material}
        />
        <mesh
          name="Object261"
          castShadow
          receiveShadow
          geometry={nodes.Object261.geometry}
          material={nodes.Object261.material}
        />
        <mesh
          name="Object264"
          castShadow
          receiveShadow
          geometry={nodes.Object264.geometry}
          material={nodes.Object264.material}
        />
        <mesh
          name="Object265"
          castShadow
          receiveShadow
          geometry={nodes.Object265.geometry}
          material={nodes.Object265.material}
        />
        <mesh
          name="Object267"
          castShadow
          receiveShadow
          geometry={nodes.Object267.geometry}
          material={nodes.Object267.material}
        />
        <mesh
          name="Object268"
          castShadow
          receiveShadow
          geometry={nodes.Object268.geometry}
          material={nodes.Object268.material}
        />
        <mesh
          name="Object269"
          castShadow
          receiveShadow
          geometry={nodes.Object269.geometry}
          material={nodes.Object269.material}
        />
        <mesh
          name="Object369"
          castShadow
          receiveShadow
          geometry={nodes.Object369.geometry}
          material={nodes.Object369.material}
        />
        <mesh
          name="Object370"
          castShadow
          receiveShadow
          geometry={nodes.Object370.geometry}
          material={nodes.Object370.material}
        />
        <mesh
          name="Object371"
          castShadow
          receiveShadow
          geometry={nodes.Object371.geometry}
          material={nodes.Object371.material}
        />
        <mesh
          name="Object372"
          castShadow
          receiveShadow
          geometry={nodes.Object372.geometry}
          material={nodes.Object372.material}
        />
        <mesh
          name="Object373"
          castShadow
          receiveShadow
          geometry={nodes.Object373.geometry}
          material={nodes.Object373.material}
        />
        <mesh
          name="Object374"
          castShadow
          receiveShadow
          geometry={nodes.Object374.geometry}
          material={nodes.Object374.material}
        />
        <mesh
          name="Object375"
          castShadow
          receiveShadow
          geometry={nodes.Object375.geometry}
          material={nodes.Object375.material}
        />
        <mesh
          name="Object376"
          castShadow
          receiveShadow
          geometry={nodes.Object376.geometry}
          material={nodes.Object376.material}
        />
        <mesh
          name="Object377"
          castShadow
          receiveShadow
          geometry={nodes.Object377.geometry}
          material={nodes.Object377.material}
        />
        <mesh
          name="Object378"
          castShadow
          receiveShadow
          geometry={nodes.Object378.geometry}
          material={nodes.Object378.material}
        />
        <mesh
          name="Object380"
          castShadow
          receiveShadow
          geometry={nodes.Object380.geometry}
          material={nodes.Object380.material}
        />
        <mesh
          name="Object382"
          castShadow
          receiveShadow
          geometry={nodes.Object382.geometry}
          material={nodes.Object382.material}
        />
        <mesh
          name="Object383"
          castShadow
          receiveShadow
          geometry={nodes.Object383.geometry}
          material={nodes.Object383.material}
        />
        <mesh
          name="Object387"
          castShadow
          receiveShadow
          geometry={nodes.Object387.geometry}
          material={nodes.Object387.material}
        />
        <mesh
          name="Object388"
          castShadow
          receiveShadow
          geometry={nodes.Object388.geometry}
          material={nodes.Object388.material}
        />
        <mesh
          name="Object389"
          castShadow
          receiveShadow
          geometry={nodes.Object389.geometry}
          material={nodes.Object389.material}
        />
        <mesh
          name="Object390"
          castShadow
          receiveShadow
          geometry={nodes.Object390.geometry}
          material={nodes.Object390.material}
        />
        <mesh
          name="Object391"
          castShadow
          receiveShadow
          geometry={nodes.Object391.geometry}
          material={nodes.Object391.material}
        />
        <mesh
          name="Object392"
          castShadow
          receiveShadow
          geometry={nodes.Object392.geometry}
          material={nodes.Object392.material}
        />
        <mesh
          name="Object393"
          castShadow
          receiveShadow
          geometry={nodes.Object393.geometry}
          material={nodes.Object393.material}
        />
        <mesh
          name="Object399"
          castShadow
          receiveShadow
          geometry={nodes.Object399.geometry}
          material={nodes.Object399.material}
        />
        <mesh
          name="Object400"
          castShadow
          receiveShadow
          geometry={nodes.Object400.geometry}
          material={nodes.Object400.material}
        />
        <mesh
          name="Object402"
          castShadow
          receiveShadow
          geometry={nodes.Object402.geometry}
          material={nodes.Object402.material}
        />
        <mesh
          name="Object405"
          castShadow
          receiveShadow
          geometry={nodes.Object405.geometry}
          material={nodes.Object405.material}
        />
        <mesh
          name="Object406"
          castShadow
          receiveShadow
          geometry={nodes.Object406.geometry}
          material={nodes.Object406.material}
        />
        <mesh
          name="Object408"
          castShadow
          receiveShadow
          geometry={nodes.Object408.geometry}
          material={nodes.Object408.material}
        />
        <mesh
          name="Object411"
          castShadow
          receiveShadow
          geometry={nodes.Object411.geometry}
          material={nodes.Object411.material}
        />
        <mesh
          name="Object418"
          castShadow
          receiveShadow
          geometry={nodes.Object418.geometry}
          material={nodes.Object418.material}
        />
        <mesh
          name="Object420"
          castShadow
          receiveShadow
          geometry={nodes.Object420.geometry}
          material={nodes.Object420.material}
        />
        <mesh
          name="Object498"
          castShadow
          receiveShadow
          geometry={nodes.Object498.geometry}
          material={nodes.Object498.material}
        />
        <mesh
          name="Object500"
          castShadow
          receiveShadow
          geometry={nodes.Object500.geometry}
          material={nodes.Object500.material}
        />
        <mesh
          name="Object503"
          castShadow
          receiveShadow
          geometry={nodes.Object503.geometry}
          material={nodes.Object503.material}
        />
        <mesh
          name="Object505"
          castShadow
          receiveShadow
          geometry={nodes.Object505.geometry}
          material={nodes.Object505.material}
        />
        <mesh
          name="Object517"
          castShadow
          receiveShadow
          geometry={nodes.Object517.geometry}
          material={nodes.Object517.material}
        />
        <mesh
          name="Object518"
          castShadow
          receiveShadow
          geometry={nodes.Object518.geometry}
          material={nodes.Object518.material}
        />
        <mesh
          name="Object532"
          castShadow
          receiveShadow
          geometry={nodes.Object532.geometry}
          material={nodes.Object532.material}
        />
        <mesh
          name="Object348"
          castShadow
          receiveShadow
          geometry={nodes.Object348.geometry}
          material={nodes.Object348.material}
        />
        <mesh
          name="Object404"
          castShadow
          receiveShadow
          geometry={nodes.Object404.geometry}
          material={nodes.Object404.material}
        />
        <mesh
          name="Object072"
          castShadow
          receiveShadow
          geometry={nodes.Object072.geometry}
          material={nodes.Object072.material}
        />
        <mesh
          name="Object239"
          castShadow
          receiveShadow
          geometry={nodes.Object239.geometry}
          material={nodes.Object239.material}
        />
        <mesh
          name="Object368"
          castShadow
          receiveShadow
          geometry={nodes.Object368.geometry}
          material={nodes.Object368.material}
        />
        <mesh
          name="Object385"
          castShadow
          receiveShadow
          geometry={nodes.Object385.geometry}
          material={nodes.Object385.material}
        />
        <mesh
          name="Object415"
          castShadow
          receiveShadow
          geometry={nodes.Object415.geometry}
          material={nodes.Object415.material}
        />
        <mesh
          name="Object419"
          castShadow
          receiveShadow
          geometry={nodes.Object419.geometry}
          material={nodes.Object419.material}
        />
        <mesh
          name="Object519"
          castShadow
          receiveShadow
          geometry={nodes.Object519.geometry}
          material={nodes.Object519.material}
        />
        <mesh
          name="Object574"
          castShadow
          receiveShadow
          geometry={nodes.Object574.geometry}
          material={nodes.Object574.material}
          position={[-36.06, 5.1, -12.46]}
          rotation={[0, 1.57, 0]}
        />
        <mesh
          name="Object576"
          castShadow
          receiveShadow
          geometry={nodes.Object576.geometry}
          material={nodes.Object576.material}
          position={[-35.96, 5.1, -10.63]}
          rotation={[0, 1.57, 0]}
        />
        <mesh
          name="Object577"
          castShadow
          receiveShadow
          geometry={nodes.Object577.geometry}
          material={nodes.Object577.material}
          position={[-27.26, 0.35, -4.57]}
        />
        <mesh
          name="Object581"
          castShadow
          receiveShadow
          geometry={nodes.Object581.geometry}
          material={nodes.Object581.material}
          position={[-28.6, 0.35, -4.52]}
        />
        <mesh
          name="Object585"
          castShadow
          receiveShadow
          geometry={nodes.Object585.geometry}
          material={nodes.Object585.material}
          position={[-29.94, 0.35, -4.52]}
        />
        <mesh
          name="Object589"
          castShadow
          receiveShadow
          geometry={nodes.Object589.geometry}
          material={nodes.Object589.material}
          position={[-31.28, 0.35, -4.57]}
        />
        <mesh
          name="Object593"
          castShadow
          receiveShadow
          geometry={nodes.Object593.geometry}
          material={nodes.Object593.material}
          position={[-32.62, 0.35, -4.57]}
        />
        <mesh
          name="Object598"
          castShadow
          receiveShadow
          geometry={nodes.Object598.geometry}
          material={nodes.Object598.material}
          position={[-29.35, 5.1, -12.46]}
        />
        <mesh
          name="Object600"
          castShadow
          receiveShadow
          geometry={nodes.Object600.geometry}
          material={nodes.Object600.material}
          position={[-31.53, 5.1, -12.36]}
        />
        <mesh
          name="Object602"
          castShadow
          receiveShadow
          geometry={nodes.Object602.geometry}
          material={nodes.Object602.material}
          position={[-33.7, 5.1, -12.36]}
        />
        <mesh
          name="Object604"
          castShadow
          receiveShadow
          geometry={nodes.Object604.geometry}
          material={nodes.Object604.material}
          position={[-35.88, 5.1, -12.46]}
        />
        <mesh
          name="Object069"
          castShadow
          receiveShadow
          geometry={nodes.Object069.geometry}
          material={nodes.Object069.material}
        />
        <mesh
          name="Object070"
          castShadow
          receiveShadow
          geometry={nodes.Object070.geometry}
          material={nodes.Object070.material}
        />
        <mesh
          name="Object079"
          castShadow
          receiveShadow
          geometry={nodes.Object079.geometry}
          material={nodes.Object079.material}
        />
        <mesh
          name="Object080"
          castShadow
          receiveShadow
          geometry={nodes.Object080.geometry}
          material={nodes.Object080.material}
        />
        <mesh
          name="Object081"
          castShadow
          receiveShadow
          geometry={nodes.Object081.geometry}
          material={nodes.Object081.material}
        />
        <mesh
          name="Object082"
          castShadow
          receiveShadow
          geometry={nodes.Object082.geometry}
          material={nodes.Object082.material}
        />
        <mesh
          name="Object207"
          castShadow
          receiveShadow
          geometry={nodes.Object207.geometry}
          material={nodes.Object207.material}
        />
        <mesh
          name="Object248"
          castShadow
          receiveShadow
          geometry={nodes.Object248.geometry}
          material={nodes.Object248.material}
        />
        <mesh
          name="Object077"
          castShadow
          receiveShadow
          geometry={nodes.Object077.geometry}
          material={nodes.Object077.material}
        />
        <mesh
          name="Object209"
          castShadow
          receiveShadow
          geometry={nodes.Object209.geometry}
          material={nodes.Object209.material}
        />
        <mesh
          name="Object210"
          castShadow
          receiveShadow
          geometry={nodes.Object210.geometry}
          material={nodes.Object210.material}
        />
        <mesh
          name="Object211"
          castShadow
          receiveShadow
          geometry={nodes.Object211.geometry}
          material={nodes.Object211.material}
        />
        <mesh
          name="Object212"
          castShadow
          receiveShadow
          geometry={nodes.Object212.geometry}
          material={nodes.Object212.material}
        />
        <mesh
          name="Object213"
          castShadow
          receiveShadow
          geometry={nodes.Object213.geometry}
          material={nodes.Object213.material}
        />
        <mesh
          name="Object214"
          castShadow
          receiveShadow
          geometry={nodes.Object214.geometry}
          material={nodes.Object214.material}
        />
        <mesh
          name="Object215"
          castShadow
          receiveShadow
          geometry={nodes.Object215.geometry}
          material={nodes.Object215.material}
        />
        <mesh
          name="Object216"
          castShadow
          receiveShadow
          geometry={nodes.Object216.geometry}
          material={nodes.Object216.material}
        />
        <mesh
          name="Object217"
          castShadow
          receiveShadow
          geometry={nodes.Object217.geometry}
          material={nodes.Object217.material}
        />
        <mesh
          name="Object218"
          castShadow
          receiveShadow
          geometry={nodes.Object218.geometry}
          material={nodes.Object218.material}
        />
        <mesh
          name="Object219"
          castShadow
          receiveShadow
          geometry={nodes.Object219.geometry}
          material={nodes.Object219.material}
        />
        <mesh
          name="Object220"
          castShadow
          receiveShadow
          geometry={nodes.Object220.geometry}
          material={nodes.Object220.material}
        />
        <mesh
          name="Object221"
          castShadow
          receiveShadow
          geometry={nodes.Object221.geometry}
          material={nodes.Object221.material}
        />
        <mesh
          name="Object222"
          castShadow
          receiveShadow
          geometry={nodes.Object222.geometry}
          material={nodes.Object222.material}
        />
        <mesh
          name="Object223"
          castShadow
          receiveShadow
          geometry={nodes.Object223.geometry}
          material={nodes.Object223.material}
        />
        <mesh
          name="Object224"
          castShadow
          receiveShadow
          geometry={nodes.Object224.geometry}
          material={nodes.Object224.material}
        />
        <mesh
          name="Object225"
          castShadow
          receiveShadow
          geometry={nodes.Object225.geometry}
          material={nodes.Object225.material}
        />
        <mesh
          name="Object226"
          castShadow
          receiveShadow
          geometry={nodes.Object226.geometry}
          material={nodes.Object226.material}
        />
        <mesh
          name="Object227"
          castShadow
          receiveShadow
          geometry={nodes.Object227.geometry}
          material={nodes.Object227.material}
        />
        <mesh
          name="Object228"
          castShadow
          receiveShadow
          geometry={nodes.Object228.geometry}
          material={nodes.Object228.material}
        />
        <mesh
          name="Object229"
          castShadow
          receiveShadow
          geometry={nodes.Object229.geometry}
          material={nodes.Object229.material}
        />
        <mesh
          name="Object230"
          castShadow
          receiveShadow
          geometry={nodes.Object230.geometry}
          material={nodes.Object230.material}
        />
        <mesh
          name="Object231"
          castShadow
          receiveShadow
          geometry={nodes.Object231.geometry}
          material={nodes.Object231.material}
        />
        <mesh
          name="Object232"
          castShadow
          receiveShadow
          geometry={nodes.Object232.geometry}
          material={nodes.Object232.material}
        />
        <mesh
          name="Object233"
          castShadow
          receiveShadow
          geometry={nodes.Object233.geometry}
          material={nodes.Object233.material}
        />
        <mesh
          name="Object234"
          castShadow
          receiveShadow
          geometry={nodes.Object234.geometry}
          material={nodes.Object234.material}
        />
        <mesh
          name="Object235"
          castShadow
          receiveShadow
          geometry={nodes.Object235.geometry}
          material={nodes.Object235.material}
        />
        <mesh
          name="Object204"
          castShadow
          receiveShadow
          geometry={nodes.Object204.geometry}
          material={materials["floor-granite"]}
        />
        <mesh
          name="Object403"
          castShadow
          receiveShadow
          geometry={nodes.Object403.geometry}
          material={nodes.Object403.material}
        />
        <mesh
          name="Object410"
          castShadow
          receiveShadow
          geometry={nodes.Object410.geometry}
          material={nodes.Object410.material}
        />
        <mesh
          name="Object499"
          castShadow
          receiveShadow
          geometry={nodes.Object499.geometry}
          material={nodes.Object499.material}
        />
        <mesh
          name="Object501"
          castShadow
          receiveShadow
          geometry={nodes.Object501.geometry}
          material={nodes.Object501.material}
        />
        <mesh
          name="Object502"
          castShadow
          receiveShadow
          geometry={nodes.Object502.geometry}
          material={nodes.Object502.material}
        />
        <mesh
          name="Object504"
          castShadow
          receiveShadow
          geometry={nodes.Object504.geometry}
          material={nodes.Object504.material}
        />
        <mesh
          name="Object527"
          castShadow
          receiveShadow
          geometry={nodes.Object527.geometry}
          material={nodes.Object527.material}
        />
        <mesh
          name="Object546"
          castShadow
          receiveShadow
          geometry={nodes.Object546.geometry}
          material={nodes.Object546.material}
        />
        <mesh
          name="Object570"
          castShadow
          receiveShadow
          geometry={nodes.Object570.geometry}
          material={nodes.Object570.material}
        />
        <mesh
          name="Object256"
          castShadow
          receiveShadow
          geometry={nodes.Object256.geometry}
          material={nodes.Object256.material}
        />
        <mesh
          name="Object471"
          castShadow
          receiveShadow
          geometry={nodes.Object471.geometry}
          material={nodes.Object471.material}
        />
        <mesh
          name="Object152"
          castShadow
          receiveShadow
          geometry={nodes.Object152.geometry}
          material={nodes.Object152.material}
        />
        <mesh
          name="Object270"
          castShadow
          receiveShadow
          geometry={nodes.Object270.geometry}
          material={nodes.Object270.material}
        />
        <mesh
          name="Object271"
          castShadow
          receiveShadow
          geometry={nodes.Object271.geometry}
          material={nodes.Object271.material}
        />
        <mesh
          name="Object272"
          castShadow
          receiveShadow
          geometry={nodes.Object272.geometry}
          material={nodes.Object272.material}
        />
        <mesh
          name="Object273"
          castShadow
          receiveShadow
          geometry={nodes.Object273.geometry}
          material={nodes.Object273.material}
        />
        <mesh
          name="Object274"
          castShadow
          receiveShadow
          geometry={nodes.Object274.geometry}
          material={nodes.Object274.material}
        />
        <mesh
          name="Object275"
          castShadow
          receiveShadow
          geometry={nodes.Object275.geometry}
          material={nodes.Object275.material}
        />
        <mesh
          name="Object276"
          castShadow
          receiveShadow
          geometry={nodes.Object276.geometry}
          material={nodes.Object276.material}
        />
        <mesh
          name="Object277"
          castShadow
          receiveShadow
          geometry={nodes.Object277.geometry}
          material={nodes.Object277.material}
        />
        <mesh
          name="Object278"
          castShadow
          receiveShadow
          geometry={nodes.Object278.geometry}
          material={nodes.Object278.material}
        />
        <mesh
          name="Object279"
          castShadow
          receiveShadow
          geometry={nodes.Object279.geometry}
          material={nodes.Object279.material}
        />
        <mesh
          name="Object280"
          castShadow
          receiveShadow
          geometry={nodes.Object280.geometry}
          material={nodes.Object280.material}
        />
        <mesh
          name="Object281"
          castShadow
          receiveShadow
          geometry={nodes.Object281.geometry}
          material={nodes.Object281.material}
        />
        <mesh
          name="Object282"
          castShadow
          receiveShadow
          geometry={nodes.Object282.geometry}
          material={nodes.Object282.material}
        />
        <mesh
          name="Object283"
          castShadow
          receiveShadow
          geometry={nodes.Object283.geometry}
          material={nodes.Object283.material}
        />
        <mesh
          name="Object068"
          castShadow
          receiveShadow
          geometry={nodes.Object068.geometry}
          material={nodes.Object068.material}
        />
        <mesh
          name="Object522"
          castShadow
          receiveShadow
          geometry={nodes.Object522.geometry}
          material={nodes.Object522.material}
        />
        <mesh
          name="Object523"
          castShadow
          receiveShadow
          geometry={nodes.Object523.geometry}
          material={nodes.Object523.material}
        />
        <mesh
          name="Object524"
          castShadow
          receiveShadow
          geometry={nodes.Object524.geometry}
          material={nodes.Object524.material}
        />
        <mesh
          name="Object525"
          castShadow
          receiveShadow
          geometry={nodes.Object525.geometry}
          material={nodes.Object525.material}
        />
      </group>
    </group>
  );
}

// useGLTF.preload("/assets/basemodel.glb");
useGLTF.preload(modelUrl);


