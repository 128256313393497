import React, {useEffect} from 'react';
import { isBrowser } from 'react-device-detect';
import { useRecoilState } from "recoil";
import { pauseAtom as pauseState } from "../atom";
import '../css/App.css';
import Landing from '../components/Landing';

function App() {

  const [isPause, setPause] = useRecoilState(pauseState);

  useEffect(() => {
    setPause(false);

    if (!isBrowser) {
      window.location.href="/more";
    }

    window.onpageshow = function(event) {
      if (event.persisted) {
        window.location.reload();
      }
    };
 }, []);



  return (
    <div className="App">
      <Landing/>
    </div>
  );
}

export default App;
