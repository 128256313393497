import React, { useState, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { nftMenuAtom as nftMenuState,  targetLabelAtom as targetLabelState, connectWalletAtom as connectWalletState  } from "../atom";
import { Connection } from '@metaplex/js';
import { Metadata } from '@metaplex-foundation/mpl-token-metadata';
import NftMenu from "./NftMenu";
import phantomLogo from '../assets/img/phantomLogo.png';
import phantomLogoInactive from '../assets/img/phantomLogoInactive.png';


const ConnectPhantom = () => {

  const [hasPhantom, setHasPhantom] = useState(null);
  const [connected, setConnected] = useRecoilState(connectWalletState);
  const [publicKey, setPublicKey] = useState(null);
  const [showNFT, setShowNFT] = useRecoilState(nftMenuState);
  const targetLabel = useRecoilValue(targetLabelState);
  const [nftData, setNftData] = useState([]);


  useEffect(() => {
    if ("solana" in window) {
      const provider = window.solana;
      if (provider.isPhantom) {
        setHasPhantom(true);
      } else {
        setHasPhantom(false);
      }
    } else {
      setHasPhantom(false);
    }
  }, []);

  useEffect(() => {
    async function fetchNft() {
      const connection = new Connection('mainnet-beta');
      let nftRes = await Metadata.findDataByOwner(connection, publicKey);
      return nftRes;
    }

    if(connected && publicKey){
      fetchNft().then((res) => {
        res.forEach(async (nft,index) => {
          var response = await fetch(nft.data.uri);
          var data = await response.json();
          res[index]=data
        });
        setNftData(res)
      });
    }
  }, [connected,publicKey])

  useEffect(() => {
    const toggleNFTMenu = (e) => {
      if (!connected) return;
      // if (!targetLabel) return;
      if (e.code === "KeyI") {
        if (!targetLabel) {
          setShowNFT(false);
        } else {
          setShowNFT(!showNFT);
        }
      }
    }
    document.addEventListener("keydown", toggleNFTMenu);
    return () => document.removeEventListener("keydown", toggleNFTMenu);
  },[connected,publicKey,showNFT,targetLabel])

  function requestConnect() {
    if (!hasPhantom) {
      return window.open("https://phantom.app/", "_blank");
    }
    if (connected) {
        console.log("disconnecting..")
        try {
            window.solana.disconnect()
            window.solana.on('disconnect', () => {
              setConnected(false);
              setPublicKey(null);
              setShowNFT(false);
            });
        } catch (err) {
            // { code: 4001, message: 'User rejected the request.' }
        }
    } else {
        console.log("connecting..")
        try {
            window.solana.connect();
            window.solana.on('connect', () => {
              setConnected(true);
              setPublicKey(window.solana.publicKey.toString());
            });
        } catch (err) {
            // { code: 4001, message: 'User rejected the request.' }
        }
    }
  }

  const nftMsg = () => {
    if(connected){
      return (
        <button className="nftMsg">
          <span className="inputKey">I</span> to toggle NFT Menu.
        </button>
        )
      // if(!showNFT){
      //   return (
      //   <button className="nftMsg">
      //     <span className="inputKey">I</span> to open NFT Inventory.
      //   </button>
      //   )
      // } else {
      //   return (
      //     <button className="nftMsg">
      //       <span className="inputKey">I</span> to close NFT Inventory.
      //     </button>
      //   )
      // }
    }
  }

  return (
    <>
      {showNFT && <NftMenu nfts={nftData}/>}
      <div className="walletBtnWrapper">
        <button className="connectWalletBtn" onClick={requestConnect}>
          {connected && <img src={phantomLogo} />}
          {!connected && <img src={phantomLogoInactive} />}
        </button>
        {/* {connected && <button className="nftBtn" onClick={getNFTData}>
          NFT Menu
        </button>} */}
      </div>
      {/* {nftMsg()} */}
    </>
  );
};

export default ConnectPhantom;