import React, { useEffect, useRef, useState } from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import * as THREE from "three";
import { useRecoilState } from "recoil";
import { connectWalletAtom as connectWalletState, targetLabelAtom as targetLabelState, nftMenuAtom as nftMenuState,
nftUrlAtom as nftUrlState } from "../../atom";
import blanktexture from "../../assets/img/blanktexture.png";

const modelUrl = process.env.REACT_APP_MODELPATH+"diningcanvas.glb";

export default function DiningCanvasModel(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF(modelUrl);

  const canvasName = "dining";
  const [textureUrl, setTextureUrl] = useState(blanktexture);
  const imgTexture = useTexture(textureUrl);
  imgTexture.center = new THREE.Vector2(0.5, 0.5);
  imgTexture.rotation = -Math.PI/100;
  imgTexture.flipY = false;


  const [connected, setConnected] = useRecoilState(connectWalletState);
  const [targetLabel, setTargetLabel] = useRecoilState(targetLabelState);
  const [showNFT, setShowNFT] = useRecoilState(nftMenuState);
  const [nftUrl, setNftUrl] = useRecoilState(nftUrlState);

  const targetObject = (action) => {
    const targetObj = "Dining Canvas";
    switch(action){
      case "pointEnter":
        setTargetLabel({"name": canvasName,"display":targetObj})
        break;
      case "pointLeave":
        setTargetLabel(null)
        setShowNFT(false)
        break;
      case "click":
        if(connected) setShowNFT(!showNFT);
        break;
    }
  }

  useEffect(() => {
    // if(nftUrl) {
    //   setTextureUrl(nftUrl);
    // }
    Object.entries(nftUrl).forEach(([name, url]) => {
      if(name == canvasName && url) setTextureUrl(url);
    });
  },[nftUrl])

  return (
    <group ref={group} {...props} dispose={null} >
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["960x640png"].geometry}
        material={materials["960x640"]}
        onPointerEnter={e => {
          targetObject("pointEnter");
        }}
        onPointerLeave={e => {
          targetObject("pointLeave");
        }}
        onClick={e => {
          targetObject("click")
        }}
      >
        {nftUrl[canvasName] && <meshStandardMaterial map={imgTexture} />}
      </mesh>
    </group>
  );
}

useGLTF.preload(modelUrl);