import { atom } from "recoil";

// Pause Menu Recoil State
export const pauseAtom = atom({
    key: "pause",
    default: false
})

//Connect Wallet State
export const connectWalletAtom = atom({
    key:"connectWallet",
    default: false
})

//NFT Menu State
export const nftMenuAtom = atom({
    key: "nftMenu",
    default: false
})

//Target Object Label State
export const targetLabelAtom = atom({
    key: "targetLabel",
    default: null
})

//Nft Menu Pointer State
export const nftPointerAtom = atom({
    key: "nftPointer",
    default: 0
})

//Nft Menu Pointer State
export const nftUrlAtom = atom({
    key: "nftUrl",
    default: {
        "entrance": null,
        "dining": null
    }
})

