/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

const modelUrl = process.env.REACT_APP_MODELPATH+"waterlight.glb";

export default function Model(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF(modelUrl);
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object550.geometry}
        material={nodes.Object550.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object551.geometry}
        material={nodes.Object551.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object552.geometry}
        material={nodes.Object552.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object553.geometry}
        material={nodes.Object553.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object554.geometry}
        material={nodes.Object554.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object555.geometry}
        material={nodes.Object555.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object556.geometry}
        material={nodes.Object556.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object557.geometry}
        material={nodes.Object557.material}
      />
    </group>
  );
}

useGLTF.preload(modelUrl);
