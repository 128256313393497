import React from 'react';
import { useRecoilState, useRecoilValue } from "recoil";
import { pauseAtom as pauseState } from "../atom";
import logo from '../assets/img/logo.png';
import AudioPlayer from './AudioPlayer'
import { Link } from "react-router-dom";
import bgm from "../assets/audio/artofsilence_bgm.mp3"

class ControlsInterface extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputAry: [
        {
          key: 'W',
          desc: 'Move Forward'
        },
        {
          key: 'A',
          desc: 'Move Left'
        },
        {
          key: 'S',
          desc: 'Move Backward'
        },
        {
          key: 'D',
          desc: 'Move Right'
        },
      ]
    };
  }

  render() {
    return (
      <div className="controlsInterfaceWrapper">
        <div className="">
          {this.state.inputAry.map((input, key) =>
            <div className="itemGroup" key={key}>
              <div className="inputKey">
                <span>{input.key}</span>
              </div>
              <div className="inputDesc">
                {input.desc}
              </div>
            </div>
          )}
        </div>
        {/* <div className="">
            <div className="itemGroup">
              <div className="inputKey">
                <span>P</span>
              </div>
              <div className="inputDesc">
                Open NFT Inventory
              </div>
            </div>
        </div> */}
      </div>
    );
  }
}

function Pause() {

  const [isPause, setPause] = useRecoilState(pauseState);

  function triggerMenu(val) {
    setPause(val)
  }

  return (
    <header className={`landing_screen pause ${isPause ? "" : "hide"}`}>
      <div className="flex flex-wrap justify-center items-center">
        <div className="w-full text-center">
          <img src={logo} className="App-logo" alt="logo" />
        </div>
        <button className="btn_primary mt-10" onClick={() => triggerMenu(false)}>Resume</button>
        <AudioPlayer url={bgm}/>
      </div>
      <ControlsInterface/>
    </header>
  );


}

export default Pause;
