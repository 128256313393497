import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { useCylinder } from "@react-three/cannon";

const modelUrl = process.env.REACT_APP_MODELPATH+"coffeetable.glb";

export default function CoffeeTableModel(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF(modelUrl);
  const [coffeetableblock] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[9.7,3.7,-1.1], args:[0.4,0.4,0.3]}))
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object478.geometry}
        material={nodes.Object478.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object479.geometry}
        material={nodes.Object479.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object480.geometry}
        material={nodes.Object480.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object481.geometry}
        material={nodes.Object481.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object482.geometry}
        material={nodes.Object482.material}
      />
    </group>
  );
}

useGLTF.preload(modelUrl);