import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

const modelUrl = process.env.REACT_APP_MODELPATH+"grass1.glb";
// const modelUrl = "/assets/grass1.glb";

export default function Grass1Model(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF(modelUrl);
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["Brep_3030487b-1fa1-4da1-9155-17c0617121ef"].geometry}
        material={materials.Glass}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["Brep_3030487b-1fa1-4da1-9155-17c0617121ef_1"].geometry}
        material={materials.Material}
      />
    </group>
  );
}

useGLTF.preload(modelUrl);