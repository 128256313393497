import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

const modelUrl = process.env.REACT_APP_MODELPATH+"kitchen countertop.glb";

export default function KitchenCountertopModel(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF(modelUrl);
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object287.geometry}
        material={nodes.Object287.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object288.geometry}
        material={nodes.Object288.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object289.geometry}
        material={nodes.Object289.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object290.geometry}
        material={nodes.Object290.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object291.geometry}
        material={nodes.Object291.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object292.geometry}
        material={nodes.Object292.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object293.geometry}
        material={nodes.Object293.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object294.geometry}
        material={nodes.Object294.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object295.geometry}
        material={nodes.Object295.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object296.geometry}
        material={nodes.Object296.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object297.geometry}
        material={nodes.Object297.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object298.geometry}
        material={nodes.Object298.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object299.geometry}
        material={nodes.Object299.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object300.geometry}
        material={nodes.Object300.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object301.geometry}
        material={nodes.Object301.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object302.geometry}
        material={nodes.Object302.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object305.geometry}
        material={nodes.Object305.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object306.geometry}
        material={nodes.Object306.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object311.geometry}
        material={nodes.Object311.material}
      />
    </group>
  );
}

useGLTF.preload(modelUrl);