import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import { isBrowser } from 'react-device-detect';
import '../css/App.css';
import logo from '../assets/img/logo.png';

function More() {

  useEffect(() => {
    if (isBrowser) {
      window.location.href="/";
    }
  }, [])

  const linkAry = [
    {
      name: "Official Website",
      url: "https://www.alpacaland.io/"
    },
    {
      name: "Buy Now on Magic Eden",
      url: "https://www.magiceden.io/marketplace/alpaca_land"
    },
    {
      name: "Start Staking",
      url: "https://staking.alpacaland.io/"
    }
  ]

  return (
    <div className="more_bg">
      <div className="wrapper">
        <img src={logo} className="App-logo" alt="logo" />
        <div className="my-12">
          Alpaca Land Resort is currently not supported on mobile device.<br/>
        </div>
          {linkAry.map((link, i) =>
            <a key={i} href={link.url} className="link_btn">
              {link.name}
            </a>
          )}
      </div>
    </div>
  );
}

export default More;
