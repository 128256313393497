import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { nftMenuAtom as nftMenuState, targetLabelAtom as targetLabelState , nftPointerAtom as nftPointerState, nftUrlAtom as nftUrlState } from "../atom";
import { AiOutlineEnter } from "react-icons/ai";


const NftMenu = ({nfts}) => {

  const [nftData, setNftData] = useState(nfts);
  const [showNFT, setShowNFT] = useRecoilState(nftMenuState);
  const [targetLabel, setTargetLabel] = useRecoilState(targetLabelState);
  const [pointer, setPointer] = useRecoilState(nftPointerState);
  const [nftUrl, setNftUrl] = useRecoilState(nftUrlState);
  const [nftGroupStart, setNftGroupStart] = useState(0);
  const nftGroup = 4;


  useEffect(() => {
    const movePointer = (e) => {
      switch(e.code){
        case "ArrowLeft":
          if(pointer-1 >= 0) {
            setPointer(pointer-1);
            if(pointer-1 < nftGroupStart) setNftGroupStart(nftGroupStart-nftGroup);
          }
          break;
        case "ArrowRight":
          if(pointer+1 < nftData.length) {
            setPointer(pointer+1);
            if(pointer+1 >= (nftGroupStart + nftGroup)) setNftGroupStart(nftGroupStart+nftGroup);
          }
          break;
        case "ArrowUp":
          if(pointer-2 >= 0) {
            setPointer(pointer-2)
            if(pointer-2 < nftGroupStart) setNftGroupStart(nftGroupStart-nftGroup);
          }
          break;
        case "ArrowDown":
          if(pointer+2 < nftData.length) {
            setPointer(pointer+2);
            if(pointer+2 >= (nftGroupStart + nftGroup)) setNftGroupStart(nftGroupStart+nftGroup);
          }
          break;
        case "Enter":
          let tempNftUrl = Object.assign({}, nftUrl);
          Object.entries(tempNftUrl).forEach(([name, url]) => {
            if(name == targetLabel["name"]) {
              tempNftUrl[name] = nftData[pointer]["image"];
            }
          });
          setNftUrl(tempNftUrl);
          break;
      }
    }
    document.addEventListener("keydown", movePointer);
    return () => document.removeEventListener("keydown", movePointer);
  },[pointer,nftGroupStart])

  const renderEmptyNft = () => {
    if (nftData.length > 0) {
      return (
        <div className="nftMenuInstruction">
          <div className="flex flex-wrap items-center">
            <div className="inputKey">↑ → ↓ ←</div>
            Browse
          </div>
          <div className="flex flex-wrap items-center">
            <div className="inputKey"><AiOutlineEnter className="text-2xl"/></div>
            Select
          </div>
        </div>
      )
    } else {
      return (
        <div>
          Can't load NFT collectibles.<br/>
          Please reconnect wallet.
        </div>
      )
    }

  }

  return (
    <>
        <div className="nftMenuWrapper">
          {nftData.length>0 && nftData.map(function(nft,index) {
            if(index<(nftGroupStart + nftGroup) && index >= nftGroupStart) {
              return (
                <div key={index} className={"nftWrapper " + (index == pointer ? 'selected' : '')}>
                  <img src={nft.image}/>
                </div>
              )
            }
          })}
          {renderEmptyNft()}
        </div>
    </>
  );
};

export default NftMenu;

