import { useCylinder } from "@react-three/cannon"

export const SecondFloor = (props) => {
    const [afloor1] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[9,3.3,1.6], args:[1,1,0.1]}))
    const [afloor2] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[9,3.3,1], args:[1,1,0.1]}))
    const [afloor3] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[9,3.3,0.4], args:[1,1,0.1]}))
    const [afloor4] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[9,3.3,-0.2], args:[1,1,0.1]}))
    const [afloor5] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[9,3.3,-0.6], args:[1,1,0.1]}))
    const [afloor6] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[9,3.3,-1], args:[1,1,0.1]}))
    const [afloor7] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[9,3.3,-1.4], args:[1,1,0.1]}))
    const [afloor8] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[9,3.3,-1.8], args:[1,1,0.1]}))
    const [afloor9] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[9,3.3,-2.2], args:[1,1,0.1]}))
    const [afloor10] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[9,3.3,-2.6], args:[1,1,0.1]}))
    const [afloor11] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[9,3.3,-3], args:[1,1,0.1]}))
    const [afloor12] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[9,3.3,-3.4], args:[1,1,0.1]}))

    const [bfloor1] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[10,3.3,1.6], args:[1,1,0.1]}))
    const [bfloor2] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[10,3.3,1], args:[1,1,0.1]}))
    const [bfloor3] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[10,3.3,0.4], args:[1,1,0.1]}))
    const [bfloor4] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[10,3.3,-0.2], args:[1,1,0.1]}))
    const [bfloor5] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[10,3.3,-0.6], args:[1,1,0.1]}))
    const [bfloor6] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[10,3.3,-1], args:[1,1,0.1]}))
    const [bfloor7] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[10,3.3,-1.4], args:[1,1,0.1]}))
    const [bfloor8] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[10,3.3,-1.8], args:[1,1,0.1]}))
    const [bfloor9] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[10,3.3,-2.2], args:[1,1,0.1]}))
    const [bfloor10] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[10,3.3,-2.6], args:[1,1,0.1]}))
    const [bfloor11] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[10,3.3,-3], args:[1,1,0.1]}))
    const [bfloor12] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[10,3.3,-3.4], args:[1,1,0.1]}))

    const [cfloor1] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[11,3.3,1.6], args:[1,1,0.1]}))
    const [cfloor2] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[11,3.3,1], args:[1,1,0.1]}))
    const [cfloor3] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[11,3.3,0.4], args:[1,1,0.1]}))
    const [cfloor4] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[11,3.3,-0.2], args:[1,1,0.1]}))
    const [cfloor5] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[11,3.3,-0.6], args:[1,1,0.1]}))
    const [cfloor6] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[11,3.3,-1], args:[1,1,0.1]}))
    const [cfloor7] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[11,3.3,-1.4], args:[1,1,0.1]}))
    const [cfloor8] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[11,3.3,-1.8], args:[1,1,0.1]}))
    const [cfloor9] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[11,3.3,-2.2], args:[1,1,0.1]}))
    const [cfloor10] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[11,3.3,-2.6], args:[1,1,0.1]}))
    const [cfloor11] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[11,3.3,-3], args:[1,1,0.1]}))
    const [cfloor12] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[11,3.3,-3.4], args:[1,1,0.1]}))

    const [dfloor1] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[12,3.3,1.6], args:[1,1,0.1]}))
    const [dfloor2] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[12,3.3,1], args:[1,1,0.1]}))
    const [dfloor3] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[12,3.3,0.4], args:[1,1,0.1]}))
    const [dfloor4] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[12,3.3,-0.2], args:[1,1,0.1]}))
    const [dfloor5] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[12,3.3,-0.6], args:[1,1,0.1]}))
    const [dfloor6] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[12,3.3,-1], args:[1,1,0.1]}))
    const [dfloor7] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[12,3.3,-1.4], args:[1,1,0.1]}))
    const [dfloor8] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[12,3.3,-1.8], args:[1,1,0.1]}))
    const [dfloor9] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[12,3.3,-2.2], args:[1,1,0.1]}))
    const [dfloor10] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[12,3.3,-2.6], args:[1,1,0.1]}))
    const [dfloor11] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[12,3.3,-3], args:[1,1,0.1]}))
    const [dfloor12] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[12,3.3,-3.4], args:[1,1,0.1]}))

    const [efloor1] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[13,3.3,1.6], args:[1,1,0.3]}))
    const [efloor2] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[13,3.3,1], args:[1,1,0.3]}))
    const [efloor3] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[13,3.3,0.4], args:[1,1,0.3]}))
    const [efloor4] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[13,3.3,-0.2], args:[1,1,0.3]}))
    const [efloor5] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[13,3.3,-0.6], args:[1,1,0.3]}))
    const [efloor6] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[13,3.3,-1], args:[1,1,0.3]}))
    const [efloor7] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[13,3.3,-1.4], args:[1,1,0.3]}))
    const [efloor8] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[13,3.3,-1.8], args:[1,1,0.3]}))
    const [efloor9] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[13,3.3,-2.2], args:[1,1,0.3]}))
    const [efloor10] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[13,3.3,-2.6], args:[1,1,0.3]}))
    const [efloor11] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[13,3.3,-3], args:[1,1,0.1]}))
    const [efloor12] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[13,3.3,-3.4], args:[1,1,0.1]}))

    const [ffloor1] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[14,3.3,1.6], args:[1,1,0.3]}))
    const [ffloor2] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[14,3.3,1], args:[1,1,0.3]}))
    const [ffloor3] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[14,3.3,0.4], args:[1,1,0.3]}))
    const [ffloor4] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[14,3.3,-0.2], args:[1,1,0.3]}))
    const [ffloor5] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[14,3.3,-0.6], args:[1,1,0.3]}))
    const [ffloor6] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[14,3.3,-1], args:[1,1,0.3]}))
    const [ffloor7] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[14,3.3,-1.4], args:[1,1,0.3]}))
    const [ffloor8] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[14,3.3,-1.8], args:[1,1,0.3]}))
    const [ffloor9] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[14,3.3,-2.2], args:[1,1,0.3]}))
    const [ffloor10] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[14,3.3,-2.6], args:[1,1,0.3]}))
    const [ffloor11] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[14,3.3,-3], args:[1,1,0.1]}))
    const [ffloor12] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[14,3.3,-3.4], args:[1,1,0.1]}))

    //Handrail
    const [handrail1] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[8,3.6,1], args:[0.03,0.02,1.4]}))
    const [handrail2] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[8,3.6,0.5], args:[0.03,0.02,1.4]}))
    const [handrail3] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[8,3.6,0], args:[0.03,0.02,1.4]}))
    const [handrail4] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[8,3.6,-0.5], args:[0.03,0.02,1.4]}))
    const [handrail5] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[8,3.6,-1], args:[0.03,0.02,1.4]}))
    const [handrail6] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[8,3.6,-1.5], args:[0.03,0.02,1.4]}))
    const [handrail7] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[8,3.6,-2], args:[0.03,0.02,1.4]}))


    //Roof
    const [aroof1] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[9,6.5,1.6], args:[1,1,0.1]}))
    const [aroof2] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[9,6.5,1], args:[1,1,0.1]}))
    const [aroof3] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[9,6.5,0.4], args:[1,1,0.1]}))
    const [aroof4] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[9,6.5,-0.2], args:[1,1,0.1]}))
    const [aroof5] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[9,6.5,-0.6], args:[1,1,0.1]}))
    const [aroof6] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[9,6.5,-1], args:[1,1,0.1]}))
    const [aroof7] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[9,6.5,-1.4], args:[1,1,0.1]}))
    const [aroof8] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[9,6.5,-1.8], args:[1,1,0.1]}))
    const [aroof9] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[9,6.5,-2.2], args:[1,1,0.1]}))
    const [aroof10] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[9,6.5,-2.6], args:[1,1,0.1]}))
    const [aroof11] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[9,6.5,2.2], args:[1,1,0.1]}))
    const [aroof12] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[9,6.5,2.6], args:[1,1,0.1]}))
    const [aroof13] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[9,6.5,3], args:[1,1,0.1]}))

    const [broof1] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[10,6.5,1.6], args:[1,1,0.1]}))
    const [broof2] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[10,6.5,1], args:[1,1,0.1]}))
    const [broof3] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[10,6.5,0.4], args:[1,1,0.1]}))
    const [broof4] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[10,6.5,-0.2], args:[1,1,0.1]}))
    const [broof5] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[10,6.5,-0.6], args:[1,1,0.1]}))
    const [broof6] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[10,6.5,-1], args:[1,1,0.1]}))
    const [broof7] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[10,6.5,-1.4], args:[1,1,0.1]}))
    const [broof8] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[10,6.5,-1.8], args:[1,1,0.1]}))
    const [broof9] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[10,6.5,-2.2], args:[1,1,0.1]}))
    const [broof10] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[10,6.5,-2.6], args:[1,1,0.1]}))
    const [broof11] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[10,6.5,2.2], args:[1,1,0.1]}))
    const [broof12] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[10,6.5,2.6], args:[1,1,0.1]}))
    const [broof13] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[10,6.5,3], args:[1,1,0.1]}))

    const [croof1] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[11,6.5,1.6], args:[1,1,0.1]}))
    const [croof2] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[11,6.5,1], args:[1,1,0.1]}))
    const [croof3] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[11,6.5,0.4], args:[1,1,0.1]}))
    const [croof4] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[11,6.5,-0.2], args:[1,1,0.1]}))
    const [croof5] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[11,6.5,-0.6], args:[1,1,0.1]}))
    const [croof6] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[11,6.5,-1], args:[1,1,0.1]}))
    const [croof7] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[11,6.5,-1.4], args:[1,1,0.1]}))
    const [croof8] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[11,6.5,-1.8], args:[1,1,0.1]}))
    const [croof9] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[11,6.5,-2.2], args:[1,1,0.1]}))
    const [croof10] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[11,6.5,-2.6], args:[1,1,0.1]}))
    const [croof11] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[11,6.5,2.2], args:[1,1,0.1]}))
    const [croof12] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[11,6.5,2.6], args:[1,1,0.1]}))
    const [croof13] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[11,6.5,3], args:[1,1,0.1]}))

    const [droof1] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[12,6.5,1.6], args:[1,1,0.1]}))
    const [droof2] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[12,6.5,1], args:[1,1,0.1]}))
    const [droof3] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[12,6.5,0.4], args:[1,1,0.1]}))
    const [droof4] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[12,6.5,-0.2], args:[1,1,0.1]}))
    const [droof5] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[12,6.5,-0.6], args:[1,1,0.1]}))
    const [droof6] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[12,6.5,-1], args:[1,1,0.1]}))
    const [droof7] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[12,6.5,-1.4], args:[1,1,0.1]}))
    const [droof8] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[12,6.5,-1.8], args:[1,1,0.1]}))
    const [droof9] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[12,6.5,-2.2], args:[1,1,0.1]}))
    const [droof10] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[12,6.5,-2.6], args:[1,1,0.1]}))
    const [droof11] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[12,6.5,2.2], args:[1,1,0.1]}))
    const [droof12] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[12,6.5,2.6], args:[1,1,0.1]}))
    const [droof13] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[12,6.5,3], args:[1,1,0.1]}))


    const [eroof1] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[13,6.5,1.6], args:[1,1,0.1]}))
    const [eroof2] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[13,6.5,1], args:[1,1,0.1]}))
    const [eroof3] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[13,6.5,0.4], args:[1,1,0.1]}))
    const [eroof4] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[13,6.5,-0.2], args:[1,1,0.1]}))
    const [eroof5] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[13,6.5,-0.6], args:[1,1,0.1]}))
    const [eroof6] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[13,6.5,-1], args:[1,1,0.1]}))
    const [eroof7] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[13,6.5,-1.4], args:[1,1,0.1]}))
    const [eroof8] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[13,6.5,-1.8], args:[1,1,0.1]}))
    const [eroof9] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[13,6.5,-2.2], args:[1,1,0.1]}))
    const [eroof10] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[13,6.5,-2.6], args:[1,1,0.1]}))
    const [eroof11] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[13,6.5,2.2], args:[1,1,0.1]}))
    const [eroof12] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[13,6.5,2.6], args:[1,1,0.1]}))
    const [eroof13] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[13,6.5,3], args:[1,1,0.1]}))

    const [froof1] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[14,6.5,1.6], args:[1,1,0.1]}))
    const [froof2] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[14,6.5,1], args:[1,1,0.1]}))
    const [froof3] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[14,6.5,0.4], args:[1,1,0.1]}))
    const [froof4] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[14,6.5,-0.2], args:[1,1,0.1]}))
    const [froof5] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[14,6.5,-0.6], args:[1,1,0.1]}))
    const [froof6] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[14,6.5,-1], args:[1,1,0.1]}))
    const [froof7] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[14,6.5,-1.4], args:[1,1,0.1]}))
    const [froof8] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[14,6.5,-1.8], args:[1,1,0.1]}))
    const [froof9] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[14,6.5,-2.2], args:[1,1,0.1]}))
    const [froof10] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[14,6.5,-2.6], args:[1,1,0.1]}))
    const [froof11] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[14,6.5,2.2], args:[1,1,0.1]}))
    const [froof12] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[14,6.5,2.6], args:[1,1,0.1]}))
    const [froof13] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[14,6.5,3], args:[1,1,0.1]}))

    const [groof1] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[15,6.5,1.6], args:[1,1,0.1]}))
    const [groof2] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[15,6.5,1], args:[1,1,0.1]}))
    const [groof3] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[15,6.5,0.4], args:[1,1,0.1]}))
    const [groof4] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[15,6.5,-0.2], args:[1,1,0.1]}))
    const [groof5] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[15,6.5,-0.6], args:[1,1,0.1]}))
    const [groof6] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[15,6.5,-1], args:[1,1,0.1]}))
    const [groof7] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[15,6.5,-1.4], args:[1,1,0.1]}))
    const [groof8] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[15,6.5,-1.8], args:[1,1,0.1]}))
    const [groof9] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[15,6.5,-2.2], args:[1,1,0.1]}))
    const [groof10] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[15,6.5,-2.6], args:[1,1,0.1]}))
    const [groof11] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[15,6.5,2.2], args:[1,1,0.1]}))
    const [groof12] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[15,6.5,2.6], args:[1,1,0.1]}))
    const [groof13] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[15,6.5,3], args:[1,1,0.1]}))

    //Walls At Washroom
    const [wall1] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[12.5,0,5], args:[0.005,0.005,10]}))
    const [wall2] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[12.5,0,4.5], args:[0.005,0.005,10]}))
    // const [rwall28] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[12.5,0,4], args:[0.005,0.005,10]}))
    // const [rwall29] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[12.5,0,3.5], args:[0.01,0.01,10]}))
    // const [rwall30] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[12.5,0,3], args:[0.01,0.01,10]}))

    const [wall3] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[12.5,0,2.3], args:[0.01,0.01,10]}))
    const [wall4] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[12.5,0,2], args:[0.01,0.01,10]}))

    const [wall5] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[13,0,1.4], args:[0.01,0.01,10]}))
    const [wall6] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[14,0,1.4], args:[0.01,0.01,10]}))
    const [wall7] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[15,0,1.4], args:[0.01,0.01,10]}))

    const [outerwall1] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[12.5,0,6], args:[1,1,13]}))
    const [outerwall2] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[13.5,0,6], args:[1,1,13]}))
    const [outerwall3] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[14,0,6], args:[1,1,13]}))
    const [outerwall4] = useCylinder(() => ({ mass: 0, collisionResponse:true, position:[14.5,0,6], args:[1,1,13]}))


  return (
    null
  )
}