import logo from '../assets/img/logo.png';
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { isBrowser } from 'react-device-detect';
import { render } from '@testing-library/react';

function Header() {

  const StartBtn = () => {

    if (isBrowser) {
      return (
        <Link to="/world" className="btn_primary mt-10">Start</Link>
      )
    } else {
      return (
        <Link to="/more" className="btn_primary mt-10">Not Supported on Mobile</Link>
      )
    }

  }

  return (
    <header className="landing_screen">
        <img src={logo} className="App-logo" alt="logo" />
        <StartBtn/>
    </header>
  );
}

export default Header;
