import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

const modelUrl = process.env.REACT_APP_MODELPATH+"rock3.glb";

export default function Rock3Model(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF(modelUrl);
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object109.geometry}
        material={nodes.Object109.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object110.geometry}
        material={nodes.Object110.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object111.geometry}
        material={nodes.Object111.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object112.geometry}
        material={nodes.Object112.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object113.geometry}
        material={nodes.Object113.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object114.geometry}
        material={nodes.Object114.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object115.geometry}
        material={nodes.Object115.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object116.geometry}
        material={nodes.Object116.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object117.geometry}
        material={nodes.Object117.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object118.geometry}
        material={nodes.Object118.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object119.geometry}
        material={nodes.Object119.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object120.geometry}
        material={nodes.Object120.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object121.geometry}
        material={nodes.Object121.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object122.geometry}
        material={nodes.Object122.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object123.geometry}
        material={nodes.Object123.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object124.geometry}
        material={nodes.Object124.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object350.geometry}
        material={nodes.Object350.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object351.geometry}
        material={nodes.Object351.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object352.geometry}
        material={nodes.Object352.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object353.geometry}
        material={nodes.Object353.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object354.geometry}
        material={nodes.Object354.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object355.geometry}
        material={nodes.Object355.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object356.geometry}
        material={nodes.Object356.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object357.geometry}
        material={nodes.Object357.material}
      />
    </group>
  );
}

useGLTF.preload(modelUrl);