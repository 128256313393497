import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

const modelUrl = process.env.REACT_APP_MODELPATH+"grass2.glb";
// const modelUrl = "/assets/grass2.glb";

export default function Grass2Model(props) {
    const group = useRef();
    const { nodes, materials } = useGLTF(modelUrl);
    return (
      <group ref={group} {...props} dispose={null}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane498.geometry}
          material={materials.Material}
          position={[-36.18, 0.02, -1.1]}
          rotation={[1.54, -0.05, 2.72]}
          scale={[0.06, 0.05, 0.05]}
        />
      </group>
    );
}

useGLTF.preload(modelUrl);